@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-3xl font-bold;
    }

    h2 {
        @apply text-2xl font-semibold;
    }

    h3 {
        @apply text-xl font-medium;
    }

    h4 {
        @apply text-lg font-normal;
    }

    /* List styles */
    ul,
    ol {
        @apply list-decimal pl-5;
    }

    ul {
        @apply list-disc;
    }

    li {
        @apply mb-2;
    }
}

html,
body,
#root {
    height: 100%;
    background-color: #f1efeb;
    overflow: hidden;
}

.chat-window {
    height: calc(100vH - 159px);
    overflow-y: auto;
}

.dot-typing {
    position: relative;
    margin: 7px 1em;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    box-shadow: 9984px 0 0 0 #c0bebd, 9999px 0 0 0 #c0bebd, 10014px 0 0 0 #c0bebd;
    animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #71706f, 9999px 0 0 0 #c0bebd, 10014px 0 0 0 #c0bebd;
    }

    16.667% {
        box-shadow: 9984px -10px 0 0 #71706f, 9999px 0 0 0 #c0bebd, 10014px 0 0 0 #c0bebd;
    }

    33.333% {
        box-shadow: 9984px 0 0 0 #c0bebd, 9999px 0 0 0 #71706f, 10014px 0 0 0 #c0bebd;
    }

    50% {
        box-shadow: 9984px 0 0 0 #c0bebd, 9999px -10px 0 0 #71706f, 10014px 0 0 0 #c0bebd;
    }

    66.667% {
        box-shadow: 9984px 0 0 0 #c0bebd, 9999px 0 0 0 #c0bebd, 10014px 0 0 0 #71706f;
    }

    83.333% {
        box-shadow: 9984px 0 0 0 #c0bebd, 9999px 0 0 0 #c0bebd, 10014px -10px 0 0 #71706f;
    }

    100% {
        box-shadow: 9984px 0 0 0 #c0bebd, 9999px 0 0 0 #c0bebd, 10014px 0 0 0 #c0bebd;
    }
}